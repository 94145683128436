var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        { staticClass: "pa-3", attrs: { "elevation-1": "" } },
        [
          _c("v-toolbar", { staticClass: "primary white--text text-h5" }, [
            _vm._v(" Choose Person In Charge "),
          ]),
          _c(
            "div",
            [
              _c("v-autocomplete", {
                attrs: {
                  "small-chips": "",
                  "deletable-chips": "",
                  multiple: "",
                  "item-text": "staff_name",
                  items: _vm.picOptions,
                  dense: "",
                  outlined: "",
                  label: "Person In Charge",
                  "return-object": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.staff_name) +
                            " ( " +
                            _vm._s(item.role) +
                            " ) "
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.picSelected,
                  callback: function ($$v) {
                    _vm.picSelected = $$v
                  },
                  expression: "picSelected",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }